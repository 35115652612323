import React, { useEffect, useState } from "react";
import bgLandingPage from "./HeroBgImage2.png";
import MobileHeroBgImage2 from "../../assets/MobileHeroBgImage2.png";
import { Link } from "react-router-dom";

const FirstPage = () => {
  const [isTablet, setIsTablet] = useState(window.innerWidth < 768);
 const [isMobile, setIsMobile] = useState( window.innerWidth <= 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 425);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      id="firstPage"
      className="h-[400px] md:h-[560px] lg:h-svh w-full relative bg-white dark:bg-black"
    >
      <div
        className={`absolute inset-0 ${
          isMobile ? "bg-contain" : "bg-cover" || isTablet ? "bg-contain" : "bg-cover "
        } bg-center bg-no-repeat`}
        style={{
          backgroundImage: `url(${
            isMobile ? MobileHeroBgImage2 : bgLandingPage
            
          })`,
        }}
      />

      <div className="md:relative z-10 h-[180%] md:h-full items-center text-black lg:w-[50%] md:w-[85%] w-[90%] text-center mx-auto flex flex-col justify-center  md:pb-5">
        <h1 className="2xl:text-7xl lg:text-5xl md:text-4xl text-2xl  font-semibold py-6 md:mt-64 whitespace-nowrap dark:text-white flex flex-col gap-2 md:gap-8 xl:gap-3 2xl:gap-8">
          <span>Smart Innovation</span>
          <span>Meets Infinity</span>
          <span className=" text-2xl font-normal dark:text-white hidden lg:block top-[-5px]">
            We transform innovative ideas into reality.
          </span>
        </h1>
        <div className="md:h-[200px] h-[200px] flex flex-col gap-8 md:gap-6 mt-16  md:justify-evenly">
          <h3 className="lg:text-3xl md:text-2xl text-md font-normal dark:text-white block lg:hidden ">
            We transform innovative ideas into reality.
          </h3>
          <span className="flex justify-center">
            <Link
              to={"/contactus"}
              className="text-white rounded-full md:py-4 md:px-6 py-2 px-3 md:text-lg text-sm bg-gradient-to-r from-[#ff5757] to-[#8c52ff]"
            >
              Get in Touch
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
