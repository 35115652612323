import React, { useEffect, useState } from "react";
import bgCareer3 from "./bgLandingPage.png";
import bgLandingPage from "../../assets/HeroBgImage2.png";
import MobileHeroBgImage2 from "../../assets/MobileHeroBgImage2.png";


const CareerLanding = () => {
  const [isTablet, setIsTablet] = useState(window.innerWidth < 768);
 const [isMobile, setIsMobile] = useState( window.innerWidth <= 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 425);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
   
    <div className="relative flex flex-col items-center justify-center gap-0   lg:gap-0 h-[400px] md:h-[560px] lg:h-screen overflow-hidden bg-white dark:bg-black z-0">
      {/* Half Moon */}
      <div
        className={`absolute inset-0 ${
          isMobile ? "bg-contain" : "bg-cover" || isTablet ? "bg-contain" : "bg-cover "
        } bg-center bg-no-repeat`}
        style={{
          backgroundImage: `url(${
            isMobile ? MobileHeroBgImage2 : bgLandingPage
            
          })`,
        }}
      />

      <h1 className="text-2xl md:text-5xl lg:text-7xl font-semibold mt-10 lg:mt-0 text-black dark:text-white ">
        Join Our Team
      </h1>

      {/* <div className="mt-10 md:hidden"></div> */}

      <p className="text-black dark:text-white text-lg md:text-2xl lg:text-4xl font-semibold text-center w-[70%]  md:mt-5 lg:px-2 lg:mt-6">
        Why work with us?
      </p>
      <p className="absolute z-50 dark:text-white bottom-0 md:bottom-2 xl:bottom-0 text-xs md:text-xl  xl:text-2xl 2xl:text-3xl w-[75%] font-normal text-center">
        At LunarEdge we believe in empowering creativity, fostering innovation,
        and driving success. Join a collaborative environment where your ideas
        matter
      </p>
    </div>
  );
};

export default CareerLanding;
